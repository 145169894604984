import { useState, useEffect } from "react";
import { Menu, MenuItem, NavContainer, NavLogo , ButtonContainer, Nav, MobileMenu} from "./NavbarElements"
import { Link, useLocation } from "react-router-dom";
import Button from "../button/button"
// import { useNavigate } from "react-router-dom";

import { FiMenu } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";

export default function Navbar(){
    const [open,setopen] =useState(false)
    const location = useLocation()
    // let navigate = useNavigate();

    useEffect(()=>{
        console.log(location)
    },[location]);

    const toggleMenu = (e)=>{
        e.preventDefault();
        setopen(!open);
    }

   
    const navigateToLogin = ()=>{
        // window.location.replace('https://fairsurepayment.com.ng/user/login')
        // window.open('https://fairsurepayment.com.ng/user/login', "_blank")
        window.open('https://portal.fairsurepayment.com.ng/user/login', "_blank")

    }
    
    const handleClicked = ()=>{
        setopen(!open);
    }
    const CheckLocation = (path)=>{
        if(path == '/'){
            path = '/bills';
        }
        if(location.pathname === path){
            console.log('pathname: ', path)
            return 'active';
        }
    }
    return(
        <NavContainer>
            <Nav>
                <Link to='/' passto>
                    <NavLogo src="/img/logo.png" alt=""/>
                </Link>
                <Menu>
                    <MenuItem ><Link to={`http://fairsurepayment.com.ng/`} className={CheckLocation('/xc')}>Home </Link></MenuItem>
                    <MenuItem ><Link to={`/bills`} className='active'>Pay bills</Link></MenuItem>
                    {/* <MenuItem><a href={`https://fairsurepayment.com.ng/developers/index.html`}  className={CheckLocation('/docs')} target="_blank" rel="noreferrer" >Documentation </a></MenuItem> */}
                </Menu>
            </Nav>
            {/* <ButtonContainer>
                <Button text={`Login`} click={navigateToLogin} />
            </ButtonContainer> */}
            <MobileMenu open={open}>
                <button className="menubtn" onClick={toggleMenu}>
                    <FiMenu/>
                </button>
                <div className="mobile_container">
                    <button className="mobile_close" onClick={toggleMenu}>
                        <GrFormClose/>
                    </button>
                    <div className="mobile_menu">
                        <div className="">
                            <Link  to='/bills' onClick={handleClicked}>
                                <NavLogo src="/img/logo.png" alt="" />
                            </Link>
                        </div>
                        <ul>
                            <li className=""><Link to={`http://fairsurepayment.com.ng/`} onClick={handleClicked}>Home</Link></li>
                            <li className=""><Link to={`/bills`} onClick={handleClicked} className='active'>Pay Bills </Link></li>
                            {/* <li className=""><a href={`https://fairsurepayment.com.ng/developers/index.html`} onClick={handleClicked} target="_blank" rel="noreferrer" >Documentation </a></li> */}
                        </ul>
                        {/* <Button text={`Login`} click={navigateToLogin} /> */}
                       
                    </div>
                </div>
            </MobileMenu>
        </NavContainer>
    )
}