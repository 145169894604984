import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
// import Script from 'next/script'
import { isBrowser } from "react-device-detect";

// import Skeleton from 'react-loading-skeleton';

import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PaymentRequest = (props) => {
  const [billServiceId, setBillServiceId] = useState("");

  const params = useParams();
  console.log(params);

  useEffect(() => {
    // http://localhost:3000/invoice-payment/4p97zsz3iDwvnR3ZBVNs?status=SUCCESS&amount=110&ref=4p97zsz3iDwvnR3ZBVNs-744683323
    const invoiceRef = params.uniqueRef;
    if (invoiceRef === null || invoiceRef === undefined) {
      // Display error and return to landing page
      window.location = "https://bills.fairsurepayment.com.ng";
    }

    doPayReqProcessing(invoiceRef);
  }, []);

  // useEffect(()=>{
  //     if(isBrowser){
  //         setProceed(true)
  //     }else{
  //         setProceed(false)
  //     }

  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  // const getValue = async(ref, transId)=>{
  //     const response = await axios.get('https://staging-api.icadpay.com/api/AltBiller/getValue?reqId='+ref);
  //     const data = response.data;
  //     // setpowerdata(data)//

  // }

  const doPayReqProcessing = async (invoiceRef) => {
    // const response = await axios.get('http://localhost:8080/api/fairsure/invoice/payment?invoice_no='+invoiceRef);
    const response = await axios.get(
      "https://checkout.fairsurepayment.com.ng:8091/api/fairsure/invoice/payment?invoice_no=" +
        invoiceRef
    );
    console.log(response);

    // https://bills.fairsurepayment.com.ng/invoice-payment/
    /*
        *You can do some thing fancy here to show the customer what they are about to pay for*

        {
            "firstName": "emmanuel",
            "lastName": "yakubu",
            "email": "Emmanuelyak2@gmail.cpm",
            "uniqueRef": "EIHJV8DEBNOG058YHYCDWP6K7JSSCA",
            "description": "airtime",
            "amount": 10000.00,
            "phoneNumber": "08144069568",
            "publicKye": null
        }

        {
            "status": 0,
            "data": {
                "id": 2,
                "firstName": "Jus",
                "lastName": "Jay",
                "email": "jay.jus@string.bill",
                "phoneNumber": "09000000000",
                "amount": 10,
                "description": "string bill",
                "uniqueRef": "4p97zsz3iDwvnR3ZBVNs",
                "initiatedDate": "2024-07-01T04:24:22.020+00:00",
                "invoiceType": "MULTIPLE",
                "invoiceStatus": "ACTIVE",
                "merchantId": 6
            },
            "message": "00"
        }
         */

    const data = response.data.data;
    const payload = {
      // key: data.publicKye === null ? 'LIVE_7AXSCCY8R3HABAN14QP7' : data.publicKey, // this is not a demo key.
      key: "LIVE_7AXSCCY8R3HABAN14QP7", // this is not a demo key.
      email: data.email, // customer email
      amount: parseFloat(data.amount) ?? 0, // amount to be processed
      currency: "NGN", // currency
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber, // customer's phone number (optional)
      customerId: data.email,
      ref: `${Math.floor(Math.random() * 1000000000 + 1)}_${data?.uniqueRef}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      narration: data.description,
      isBill: true,
      service_bill: data.uniqueRef, //// billServiceId, ////////////
      callback_url: `${window.location.href}`, // specified redirect URL (potional)
      // callback_url: '', // specified redirect URL (potional)
      callback: (response) => {
        console.log(response);
      },
      onSuccess: (response) => {
        console.log(response);
        // const ref = response
        // localStorage.set('transactionResponse',response )

        const req = {
          transactionRef: response.reference,
          merchantTransactionRef: response.merchantTransactionRef,
        };
        // const responseUp = axios.post('https://staging-api.icadpay.com/api/payment-link', req);

        //Do anything else here e.g: show payment status
      },
      onError: (response) => {
        //Do anything or go back to home page
      },
      onClose: () => {
        // alert('window closed');
        window.location = "https://bills.fairsurepayment.com.ng";
      },
    };

    console.log(payload);
    // console.log(window.FairsurePay);

    // window.IcadPay.setup(payload);
    // window.FairsureIcadPay.setup(payload);
    // window.FairsurePay.setup(payload);
    const handler = window.FairsurePay.setup(payload);

    // const data = response.data;
    // const payload = {
    //     key: 'LIVE_7AXSCCY8R3HABAN14QP7', // this is a demo key.
    //     email: email, // customer email
    //     amount: amount_, // amount to be processed
    //     currency: "NGN", // currency
    //     first_name: fname,
    //     last_name: lname,
    //     phone_number: phone, // customer's phone number (optional)
    //     customerId: email,
    //     ref: transactionId, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
    //     narration: 'something nice',
    //     isBill:true,
    //     callback_url: window.location.href, // specified redirect URL (potional)
    //     callback: (response) => {
    //         // console.log(response);
    //     },
    //     onSuccess: (response) => {
    //       console.log(response);
    //     // getValue();

    //     },
    //     onError: (response) => {
    //     //   console.log(response);
    //     },
    //     onClose: () => {
    //     //   console.log('window closed');
    //       // alert('window closed');
    //     }
    // }

    // window.FairsureIcadPay.setup(payload);
  };

  return <></>;
};

export default PaymentRequest;
